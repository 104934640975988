@import '@angular/material/theming';

$custom-typography: mat-typography-config($font-family: '"Open Sans", "Helvetica Neue", sans-serif;');

@include mat-core($custom-typography);

$md-customprimary: (50 : #e3f2fd,
    100 : #bbdefb,
    200 : #90caf9,
    300 : #64b5f6,
    400 : #42a5f5,
    500 : #25a0f8, // primary
    600 : #1e88e5,
    700 : #1976d2,
    800 : #1565c0,
    900 : #0d47a1,
    A100 : #82b1ff,
    A200 : #448aff,
    A400 : #2979ff,
    A700 : #2962ff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    ));

$md-customaccent: (50 : #e5e6e6,
    100 : #bdc0bf,
    200 : #919695,
    300 : #656b6b,
    400 : #444c4b,
    500 : #232c2b,
    600 : #1f2726,
    700 : #1a2120,
    800 : #151b1a,
    900 : #0c1010,
    A100 : #56ffff,
    A200 : #23ffff,
    A400 : #00efef,
    A700 : #00d5d5,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    ));

$theme-primary: mat-palette($md-customprimary);
$theme-accent: mat-palette($md-customaccent);
$theme-warn: mat-palette($mat-orange);

$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);

@include angular-material-theme($theme);

:root {
    --primary: #{mat-color($theme-primary)};
    --primary-tint: #{mat-color($theme-primary, 900)};
    --accent: #{mat-color($theme-accent)};
    --warn: #{mat-color($theme-warn)};

    --grey-light: #F5F5F5;
    --grey-base: #bbbbbb;
    --grey-shade: rgba(0, 0, 0, 0.87);

    --primary-gradient-a: #25a0f8;
    --primary-gradient-b: #4011c2;
    --primary-gradient-b-light: #D2CBF6;
    --primary-gradient: linear-gradient(to right, var(--primary-gradient-a), var(--primary-gradient-b));
}
