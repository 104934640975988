/* Fonts */
@font-face {
    font-family: "Montserrat";
    font-display: optional;
    src: url("assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "Montserrat";
    font-display: optional;
    src: url("assets/fonts/Montserrat-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Montserrat";
    font-display: optional;
    src: url("assets/fonts/Montserrat-Bold.ttf");
    font-weight: bold;
}

/* Material Fonts */
@font-face {
    font-family: 'Material Icons';
    font-display: fallback;
    font-style: normal;
    font-weight: 400;
    src: url("assets/fonts/Material-Icons.woff2");
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Montserrat", "Helvetica Neue", sans-serif;
}

.container {
    padding: 10px 10px 48px 10px;
    box-sizing: border-box;
    margin: 0 auto;
}

a,
a:focus,
a:active,
a:visited {
    text-decoration: none;
    color: var(--primary);
    transition: color 0.1s ease-in-out;
}

a:hover {
    color: var(--primary-tint);
}

.weak {
    font-weight: 300;
}

h1,
h2 {
    font-weight: bold;
}

h2 {
    text-align: center;
    margin-top: 10px;
}

h3 {
    margin-top: 32px;
    margin-bottom: 16px;
    font-weight: 500;
}

p {
    text-align: justify;
    font-weight: normal;
}

@media screen and (min-width: 600px) {
    .container {
        width: 60vw;
    }
}

@media screen and (min-width: 1280px) {
    .container {
        width: 50vw;
    }

    h2 {
        font-size: 2.5rem;
    }
}

router-outlet~* {
    position: absolute;
    height: 100%;
    width: 100%;
}

.highlight-text {
    color: var(--primary);
}

.subheader {
    text-align: center;
    font-size: 1.4rem;
    padding: 32px 12px;

    .german-hint {
        color: var(--grey-base);
        display: block;
        font-size: 0.8rem;
    }

    .german-hint::before {
        content: 'Hinweis: ';
    }
}

.subheader-extended {
    text-align: center;
    padding: 32px 6px 0px 6px;
}

h3 fa-icon {
    color: var(--primary);
}

// temporary fix for expansion panels until Angular Material Issue is fixed,
// see https://github.com/angular/material2/issues/13870
mat-accordion mat-expansion-panel {
    mat-expansion-panel-header {
        height: 40px;
    }

    .mat-expansion-panel-content {
        height: 0;
    }

    &.mat-expanded {
        mat-expansion-panel-header {
            height: 64px;
        }

        .mat-expansion-panel-content {
            height: auto;
        }
    }
}
